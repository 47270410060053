import React from "react"
import WorkSheet from "../components/workSheet"
import Feedback from "../components/feedback"
import Nav from "../components/nav"
import Information from "../components/information"
import MobileDisclaimer from "../components/mobileDisclaimer"

const IndexPage = () => (
  <div className="min-h-screen bg-gray-100">
    <Nav />
    <Information />
    <MobileDisclaimer />
    <WorkSheet />
    <Feedback />
  </div>
)

export default IndexPage
